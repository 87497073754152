import React from 'react'
import { graphql } from "gatsby"
import Post from '../components/blogPost'
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const Service = ({ data }) => {
    const { postsNodes: { nodes: posts }, service: { name, internalBodyText, internalSubtitle,metaDescription,metaTitle } } = data

    const relatedPosts = posts.map(({ name, featureImage, date }) => {
        return (
            <Post title={name} key={name} date={date} image={featureImage} />
        )
    })

    const RelatedPostsWrapper = () => {
        if (relatedPosts.length === 0) {
            return null
        } else {
            return (
                <div className="related--posts">
                    <div className="spacer"></div>
                    <div className="max-650 ma text-center">
                        <h3 className="h1 m0 m-p20">To find more detailed information visit our <a className="link ul-link reverse" href="/blog">Knowledge and Insights</a> page</h3>
                    </div>
                    <div className="p20 grid no-gap mt40">
                        {relatedPosts}
                    </div>
                    <div className="spacer m-hide"></div>
                </div>
            )
        }
    }


    return (
        <Layout>
            <Seo description={metaDescription} title={metaTitle} />
            <div className="p20" style={{ backgroundColor: '#ECECEC' }}>
                <div className="title--area max-650 ma text-center">
                    <p className="h1 m0 mt40 mb40">{name}</p>
                </div>
                <div className="content max-650 ma">
                    <p className="h3 m0 mb40">{internalSubtitle}</p>
                    <div className="copy mb40 prewrap sainte" dangerouslySetInnerHTML={{ __html: internalBodyText }}></div>
                    <Link to="/contact" className="link button black mb40">Contact us</Link>
                </div>
            </div>
            <RelatedPostsWrapper />
        </Layout>)
}

export default Service


export const pageQuery = graphql`
    query service($id: String!){
        service:datoCmsService(id: {eq: $id}){
            name
            internalBodyText
            metaTitle
            metaDescription
            internalBodyTextNode {
              internal {
                content
              }
            }
            internalSubtitle
        }
        postsNodes: allDatoCmsBlogPost(filter: {service: {elemMatch: {id: {eq: $id}}}}, limit: 3)  {
            nodes {
                name
                date(formatString: "D M Y")
                featureImage {
                    gatsbyImageData
                }
            }
        }
    }
`